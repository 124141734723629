import { css } from "@emotion/react";
import tw from "twin.macro";

const getStyles = ({ isFeatured, companyIndex }) => ({
	layoutWrapper: css`
		${tw`block mb-5 bg-white rounded-lg overflow-hidden`}
		${isFeatured ? tw`mt-8 border-2 border-solid border-[#9e79e8]` : tw``}

    box-shadow: 0px 1px 2px 0px rgba(88, 102, 126, 0.12), 0px 4px 24px 0px rgba(88, 102, 126, 0.06);
	`,
	featuredCompanyHeader: {
		wrapper: "tw-p-2 tw-bg-[#faf5ff] tw-text-center",
		contentWrapper:
			"tw-leading-6 tw-font-semibold tw-text-xs tw-tracking-[-0.00875rem] xs:tw-text-sm lg:tw-text-base lg:tw-tracking-[-0.01rem]",
	},
	coupon: {
		wrapper: "tw-p-2 tw-bg-white tw-flex tw-items-center tw-justify-center",
		contentWrapper:
			"tw-w-full tw-p-2 tw-flex tw-items-center tw-justify-center tw-text-center tw-bg-[#fdeef2] tw-text-[#e1255b] tw-rounded-lg hover:tw-underline",
		contentIcon: "tw-inline-flex tw-mr-2 tw-text-2xl tw-text-[#E1255B]",
		content: "tw-text-sm sm:tw-text-base tw-font-bold",
	},
	rootGrid: css`
		${tw`flex flex-col lg:flex-row min-h-[250px] justify-center items-center lg:items-stretch py-2`}
	`,
	col1: css`
		${tw`w-full lg:w-[30%] max-w-none flex flex-col justify-center relative`}
	`,
	col2: css`
		${tw`w-[90%] lg:w-[48%] max-w-none flex flex-col justify-center items-start px-0 lg:px-2 my-2 lg:my-0`}
	`,
	col3: css`
		${tw`w-full lg:w-[22%] max-w-none flex flex-wrap flex-row lg:flex-col justify-evenly lg:justify-center items-center `}
	`,
	logoWrapper: css`
		${tw`pt-16 pb-4 px-0 lg:py-0`}
	`,
	companyLogo: {
		picture: css`
			${tw`flex justify-center`}
		`,
		image: [
			css`
				${tw`max-w-[230px] lg:max-w-[220px] my-2 lg:my-0 rounded-lg`}
			`,
		],
	},
	serviceFlag: {
		wrapper: css`
			${tw``}
		`,
		flag: css`
			${tw`ml-2 absolute top-1 lg:top-[45%] bg-[#616096] w-[28px] h-[28px] text-white font-extrabold flex justify-center items-center shadow-lightShadow rounded-full`}
		`,
		number: css`
			${tw`m-0 text-white font-medium text-sm`}
		`,
		text: css`
			${tw`bg-[#359492] top-1 w-max absolute text-white h-[28px] text-base flex justify-center items-center my-0 py-0 pl-2 pr-5 font-medium text-sm shadow-lightShadow ml-10 lg:ml-0`}

			clip-path: polygon(100% 0, calc(100% - 10px) 50%, 100% 100%, 0 100%, 0 0);
		`,
	},
	productDescription: {
		text: css`
			${tw`text-lg font-bold mb-2 text-[#000a25]`}
		`,
	},
	companyBadge: {
		wrapper: css`
			${tw`flex bg-[#00b679] justify-center items-center p-1 mb-2 rounded shadow-lightShadow`}
		`,
		text: css`
			${tw`text-white font-semibold text-xs mb-0 mx-1`}
		`,
	},
	benefits: {
		wrapper: css`
			${tw`w-full px-0`}
		`,
		list: css`
			${tw`w-full mb-0 p-0`}
		`,
		benefit: css`
			${tw`w-full text-sm text-[#000a25] pl-6 mb-2`}
			background-image: url("/react-images/common/favyCheck.svg");
			background-position: left top 7px;
			background-repeat: no-repeat;
		`,
	},
	visitLink: {
		wrapper:
			"tw-my-2 tw-mx-0 tw-flex tw-w-[165px] tw-h-[48px] tw-bg-[#359492] tw-rounded-lg tw-shadow-lightShadow tw-cursor-pointer tw-transition hover:tw-bg-[#3d9f9e] hover:tw-shadow-[0px_2px_4px_0px_#c0c0c0] lg:tw-w-[196px]",
		contentWrapper:
			"tw-px-2 tw-text-white tw-w-full tw-flex tw-items-center tw-justify-center tw-text-center tw-font-semibold tw-text-base",
	},
	reviewLink: {
		wrapper: css`
			${tw`mt-0 lg:mt-5 `}
			${companyIndex === 1 ? tw`mt-[80px] lg:mt-4` : tw``}
		`,
		text: css`
			${tw`text-[#359492] hover:underline text-base font-semibold`}
		`,
	},
	socialProofSection: css`
		${tw`flex-shrink lg:flex-shrink-0 flex-grow lg:flex-grow-0 mb-0 mt-4 lg:mt-0`}
		flex-basis: 100%;
		@media (min-width: 1024px) {
			flex-basis: 0;
		}
	`,
	socialProof: {
		wrapper:
			"tw-relative tw-flex tw-max-w-fit tw-self-center tw-rounded-lg tw-mx-auto tw-mt-4 tw-bg-white tw-p-2 [filter:drop-shadow(0px_0px_1px_rgba(88,102,126,0.48))_drop-shadow(0px_0px_24px_rgba(88,102,126,0.10))] after:tw-absolute after:tw-right-[calc(50%-6px)] after:tw-w-2.5 after:tw-h-2.5 after:tw-translate-x-0 after:tw--translate-y-2/4 after:tw-rotate-45 after:tw-bg-white after:tw-overflow-hidden after:tw-pointer-events-none md:after:tw-w-3 md:after:tw-h-3 after:tw-top-0",
		contentWrapper:
			"tw-flex tw-flex-row tw-justify-center tw-items-center tw-gap-2",
		contentIcon: "tw-text-[#F97316] tw-text-2xl md:tw-text-[32px]",
		content:
			"tw-min-w-[100px] tw-max-w-[100px] tw-font-semibold tw-text-[0.6875rem] !tw-m-0 tw-text-left tw-text-[#22293A]",
	},
	rating: {
		wrapper: css`
			${tw`flex mb-2`}
		`,
		score: css`
			${tw`font-extrabold text-[#1a1a1a] text-xl mb-0 mr-4 lg:mr-2`}
		`,
		stars: css`
			${tw`flex justify-between lg:justify-center flex-row items-center mr-4 lg:mr-2 w-fit`}
		`,
		starColor: css`
			.MuiRating-iconFilled {
				${tw`text-[#00b679]`}
			}
		`,
		count: css`
			${tw`text-sm font-semibold text-[#1a1a1a] mb-0 tracking-tight text-center ml-2`}
		`,
	},
});

export default getStyles;
